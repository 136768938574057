import React from 'react';

import { useParams } from 'react-router-dom';

import OrderDetails from '@ttstr/components/Order/OrderDetails';

const closeIcon = <i className="scribble-icon scribble-icon-close" />;

interface RouteParams {
  orderId: string;
}

const OrderDetail: React.FC = () => {
  const params = useParams<RouteParams>();

  return <OrderDetails orderId={params.orderId} closeIcon={closeIcon} />;
};

export default React.memo(OrderDetail);
