import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container } from '@ttstr/components';
import OrderDetails from '@ttstr/components/Order/OrderDetails';

const closeIcon = <i className="scribble-icon scribble-icon-close" />;

interface RouteParams {
  orderId: string;
  xtoken?: string;
}

const Details: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<RouteParams>();

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>{t('ORDERS.DETAIL.TITLE')}</title>
        </Helmet>

        <h1 className="text-center mb-4">{t('ORDERS.DETAIL.TITLE')}</h1>
        <hr />

        <OrderDetails orderId={params.orderId} xtoken={params.xtoken} closeIcon={closeIcon} />
      </Container>
    </article>
  );
};

export default React.memo(Details);
