import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';
import Navbar from 'reactstrap/lib/Navbar';

import { Product } from '@ttstr/api';
import { getLocationFromProduct } from '@ttstr/reducers/locations';
import Container from '@ttstr/components/Container/Container';
import DateComponent, { useDateTime } from '@ttstr/components/Intl/DateComponent';
import LoadingSpinner from '@ttstr/components/Loading/LoadingSpinner';
import DayRangePicker from '@ttstr/components/Input/DayRangePicker';
import { ProductDetailsContext } from '@ttstr/components/ProductDetail/ProductDetailsContext';

interface OwnProps {
  product: Product;
  className?: string;
  color?: string;
  light?: boolean;
  dark?: boolean;
}

type Props = Readonly<OwnProps>;

const ProductInfoBar: React.FC<Props> = ({ product, className, color, light, dark }) => {
  const { t } = useTranslation();
  const { loading, locations } = React.useContext(ProductDetailsContext);
  const [showMaps, setShowMaps] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [mapsWasInitialized, setMapsWasInitialized] = React.useState(false);
  const location = getLocationFromProduct(locations, product);
  const dateTime = useDateTime();

  const toggleMaps = () => {
    setShowCalendar(false);
    setShowMaps(!showMaps);
    setMapsWasInitialized(true);
  };

  const toggleCalendar = () => {
    setShowMaps(false);
    setShowCalendar(!showCalendar);
  };

  const locationElement = location ? (
    <span
      className="text-truncate"
      title={t(`PRODUCT.LOCATION`)}
      itemProp="location"
      itemScope
      itemType="http://schema.org/Place"
    >
      <i className="far fa-location-arrow text-info mr-2" />
      <strong>
        <address className="d-inline" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          {location.title} {location.city}
        </address>
      </strong>
    </span>
  ) : null;

  return (
    <>
      <section className={classNames('product-info-bar', className)}>
        <Container fluid className="d-flex flex-column justify-content-sm-between align-items-left p-0 mb-2">
          {/* <img
            src={product.image.thumb.url}
            id="product-thumb"
            className="product-thumb mr-3"
            alt={product.title}
            width="50"
          /> */}
          {/* <UncontrolledTooltip target="product-thumb">{product.title}</UncontrolledTooltip> */}

          {product.valid_start_on && (
            <span className="navbar-text">
              <i className="far fa-calendar-alt text-info mr-2" />
              <strong className="text-truncate">
                <meta itemProp="startDate" content={dateTime.format(new Date(product.valid_start_on))} />
                <meta itemProp="endDate" content={dateTime.format(new Date(product.valid_end_on))} />
                <DateComponent style="with-weekday" value={product.valid_start_on} />
                {product.valid_end_on && product.valid_end_on.toString() !== product.valid_start_on.toString() && (
                  <React.Fragment>
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    {' - '}
                    <DateComponent value={product.valid_end_on} />
                  </React.Fragment>
                )}
              </strong>
            </span>
          )}
          {product.time_open && product.time_begin && (
            <span className="navbar-text">
              <i className="far fa-clock text-info mr-2" />
              <span className="d-inline-block">
                <span>{t(`PRODUCT.DOORS_OPEN`)}</span> <span className="text-truncate">{product.time_open}</span>
              </span>
              {' / '}
              <span className="d-inline-block">
                <span>{t(`PRODUCT.START`)}</span> <span>{product.time_begin}</span>
              </span>
            </span>
          )}
          {locationElement && (
            <span
              className="text-truncate navbar-text"
              title={t(`PRODUCT.LOCATION`)}
              itemProp="location"
              itemScope
              itemType="http://schema.org/Place"
            >
              <i className="far fa-location-arrow text-info mr-2" />
              <strong>
                <address className="d-inline" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                  {location.title} {location.city}
                </address>
              </strong>
            </span>
          )}
        </Container>
        {/* <Collapse isOpen={showCalendar}>
          <Container className="d-flex justify-content-center">
            <DayRangePicker from={product.valid_start_on} to={product.valid_end_on} disabled />
          </Container>
        </Collapse>
        <Collapse isOpen={showMaps}>
          {location?.latitude && location?.longitude && (
            <iframe
              title={t('PRODUCT.MAP')}
              className="maps"
              frameBorder="0"
              sandbox="allow-scripts"
              src={
                showMaps || mapsWasInitialized
                  ? `https://www.openstreetmap.org/export/embed.html?bbox=${getBBox(location, 1000).join(
                      '%2C'
                    )}&layer=mapnik&marker=${location.latitude}%2C${location.longitude}`
                  : null
              }
            />
          )}
        </Collapse> */}
      </section>
      {loading && <LoadingSpinner label={t(`LOADING.PRODUCT_META`)} />}
    </>
  );
};

interface LatLong {
  latitude: number;
  longitude: number;
}

// @see https://help.openstreetmap.org/questions/20335/embedded-html-displays-zoomed-out/51323
function getBBox({ latitude, longitude }: LatLong, area: number) {
  const offset = area / 2;
  return [
    getCoordOffset(1, latitude, longitude, -offset),
    getCoordOffset(0, latitude, longitude, -offset),
    getCoordOffset(1, latitude, longitude, +offset),
    getCoordOffset(0, latitude, longitude, +offset),
  ];
}

function getCoordOffset(what: number, lat: number, lon: number, offset: number) {
  const earthRadius = 6378137;
  const coord = [lat, lon];

  const radOff = what === 0 ? offset / earthRadius : offset / (earthRadius * Math.cos((Math.PI * coord[0]) / 180));
  return coord[what] + (radOff * 180) / Math.PI;
}

export default React.memo(ProductInfoBar);
